import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useTranslation, Trans } from "react-i18next";
import { Container } from "@mui/material";
import { themeSourceSerifPro } from "../themeConstants";
import { ThemeProvider } from "@mui/material/styles";

const GiftDetails = (props) => {
  var { first, last, img, gifter, imgInd, message, eMail, price } = props;
  const { t } = useTranslation();
  const [width, setWidth] = React.useState(window.innerWidth);

  const bp = 800;
  const bpG = 865;

  useEffect(() => {
    const handleWindowResizing = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResizing);
    return () => {
      window.removeEventListener("resize", handleWindowResizing);
    };
  }, []);

  useEffect(() => {
    console.log(price);
    setGifter(gifter);
    setMessage(message);
    setEmail(eMail);
    setValue(imgInd);

    if (
      price === "10" ||
      price === "25" ||
      price === "50" ||
      price === "75" ||
      price === "100"
    ) {
      setValueAmount(price);
    } else {
      setNumber(price);
      setCustomAmount(price);
      price === null ? setValueAmount("") : setValueAmount(price);
    }
  }, []);

  const [sender, setGifter] = React.useState();
  const [details, setMessage] = React.useState();
  const [email, setEmail] = React.useState();
  const [value, setValue] = React.useState("");
  const [valueAmount, setValueAmount] = React.useState("");
  const [number, setNumber] = React.useState();
  const [customAmount, setCustomAmount] = React.useState();

  const handleChangeGifter = (e) => {
    setGifter(e.target.value);
    props.onChangeGifter(e.target.value);
  };

  const handleCustomAmount = (e) => {
    const sValue =
      e.target.value <= 100 && e.target.value >= 0 ? e.target.value : number;
    setCustomAmount(sValue);
    setNumber(sValue);
    setValueAmount(sValue);
    props.onChangePrice(sValue);
    props.onChangeCustomAmount(e.target.value);
  };

  const handleChangeGifterDetails = (e) => {
    setMessage(e.target.value);
    props.onChangeGifterDetails(e.target.value);
  };

  const handleChangeCard = (event) => {
    setValue(event.target.value);
    props.onChangeCard(event.target.value);
  };

  const handleChangePrice = (event) => {
    setValueAmount(event.target.value);
    props.onChangePrice(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    props.onChangeEmail(event.target.value);
  };

  return (
    <Box pl={0} pr={0}>
      <Stack justifyContent="center">
        <ThemeProvider theme={themeSourceSerifPro}>
          <Typography
            component="h3"
            fontSize={22}
            variant="h6"
            sx={{ mb: 2 }}
            align="center"
          >
            <Trans>{t("giftDetailsTo")}</Trans>
          </Typography>
        </ThemeProvider>
        <Box pl={5} pr={5}>
          <Typography
            component="h3"
            variant="h6"
            fontWeight="bold"
            sx={{ mb: 2 }}
            fontSize={26}
            align="center"
          >
            {first.toUpperCase()} {last.toUpperCase()}
          </Typography>
          <Typography
            component="h4"
            variant="body1"
            fontWeight="bold"
            fontSize={19}
            sx={{ mb: 2, mt: 4 }}
            align="left"
          >
            <Trans>{t("giftAmountHint")}</Trans>
            <ThemeProvider theme={themeSourceSerifPro}>
              <Typography fontSize={14} display="inline">
                <Trans>{t("giftAmountHintParentheses")}</Trans>
              </Typography>
            </ThemeProvider>
          </Typography>
        </Box>
        <Typography
          component="h4"
          variant="body1"
          fontWeight="bold"
          sx={{ mb: 2, ml: 2 }}
          align="center"
        >
          <FormControl>
            <RadioGroup
              row
              name="controlled-radio-buttons-group"
              value={valueAmount}
            >
              <Box
                justifyContent="center"
                alignItems="center"
                sx={{ mb: 2, mt: 2, pl: 2 }}
              >
                <FormControlLabel
                  key="10€"
                  value="10"
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#67c2bb",
                        },
                        "& svg": {
                          width: "0.4em",
                          height: "0.4em",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      component="h4"
                      variant="h6"
                      fontWeight="bold"
                      sx={{ mb: 0 }}
                      fontSize={26}
                      align="left"
                      color="black"
                    >
                      10{" "}
                      <Typography fontSize={17} display="inline">
                        €
                      </Typography>
                    </Typography>
                  }
                  onChange={handleChangePrice}
                />
              </Box>
              <Box
                justifyContent="center"
                alignItems="center"
                sx={{ mb: 2, mt: 2, pl: 2 }}
              >
                <FormControlLabel
                  key="25€"
                  value="25"
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#67c2bb",
                        },
                        "& svg": {
                          width: "0.4em",
                          height: "0.4em",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      component="h4"
                      variant="h6"
                      fontWeight="bold"
                      sx={{ mb: 0 }}
                      fontSize={26}
                      align="left"
                      color="black"
                    >
                      25{" "}
                      <Typography fontSize={17} display="inline">
                        €
                      </Typography>
                    </Typography>
                  }
                  onChange={handleChangePrice}
                />
              </Box>
              <Box
                justifyContent="center"
                alignItems="center"
                sx={{ mb: 2, mt: 2, pl: 2 }}
              >
                <FormControlLabel
                  key="50€"
                  value="50"
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#67c2bb",
                        },
                        "& svg": {
                          width: "0.4em",
                          height: "0.4em",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      component="h4"
                      variant="h6"
                      fontWeight="bold"
                      sx={{ mb: 0 }}
                      fontSize={26}
                      align="left"
                      color="black"
                    >
                      50{" "}
                      <Typography fontSize={17} display="inline">
                        €
                      </Typography>
                    </Typography>
                  }
                  onChange={handleChangePrice}
                />
              </Box>
              <Box
                justifyContent="center"
                alignItems="center"
                sx={{ mb: 2, mt: 2, pl: 2 }}
              >
                <FormControlLabel
                  key="75€"
                  value="75"
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#67c2bb",
                        },
                        "& svg": {
                          width: "0.4em",
                          height: "0.4em",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      component="h4"
                      variant="h6"
                      fontWeight="bold"
                      sx={{ mb: 0 }}
                      fontSize={26}
                      align="left"
                      color="black"
                    >
                      75{" "}
                      <Typography fontSize={17} display="inline">
                        €
                      </Typography>
                    </Typography>
                  }
                  onChange={handleChangePrice}
                />
              </Box>
              <Box
                justifyContent="center"
                alignItems="center"
                sx={{ mb: 2, mt: 2, pl: 2 }}
              >
                <FormControlLabel
                  key="100€"
                  value="100"
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#67c2bb",
                        },
                        "& svg": {
                          width: "0.4em",
                          height: "0.4em",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      component="h4"
                      variant="h6"
                      fontWeight="bold"
                      fontSize={26}
                      sx={{ mb: 0 }}
                      align="left"
                      color="black"
                    >
                      100
                      <Typography fontSize={17} display="inline">
                        €
                      </Typography>
                    </Typography>
                  }
                  onChange={handleChangePrice}
                />
              </Box>
              <Box
                justifyContent="center"
                alignItems="center"
                sx={{ mb: 2, pl: 1, mt: 2 }}
              >
                <FormControlLabel
                  key="customAmount"
                  value={customAmount}
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#67c2bb",
                        },
                        "& svg": {
                          width: "0.5em",
                          height: "0.5em",
                        },
                      }}
                    />
                  }
                  label={
                    <TextField
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "25px",

                            borderColor: "#67c2bb",
                          },
                          "&.Mui-focused fieldset": {
                            borderRadius: "25px",
                            borderColor: "#ff614e",
                          },
                          "&:hover fieldset": {
                            borderRadius: "25px",
                            borderColor: "#ff614e",
                          },
                        },
                      }}
                      value={number}
                      type="tel"
                      style={{ borderRadius: "20px", maxWidth: 120 }}
                      placeholder="Max 100€"
                      inputProps={{
                        maxLength: 3,
                        resize: { fontSize: 26 },
                        style: { textAlign: "center", fontSize: 20 },
                      }}
                      onChange={handleCustomAmount}
                    />
                  }
                  onChange={handleChangePrice}
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Typography>
        <Box pl={5} pr={5}>
          <Typography
            component="h4"
            variant="body1"
            fontWeight="bold"
            fontSize={19}
            sx={{ mb: 2 }}
            align="left"
          >
            <Trans>{t("giftFromHint")}</Trans>
            <ThemeProvider theme={themeSourceSerifPro}>
              <Typography fontSize={14} display="inline">
                <Trans>{t("giftFromHintParentheses")}</Trans>
              </Typography>
            </ThemeProvider>
          </Typography>
        </Box>
        <Container maxWidth="md">
          <TextField
            fullWidth={true}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "25px",

                  borderColor: "#67c2bb",
                },
                "&.Mui-focused fieldset": {
                  borderRadius: "25px",
                  borderColor: "#ff614e",
                },
                "&:hover fieldset": {
                  borderRadius: "25px",
                  borderColor: "#ff614e",
                },
              },
              mb: 5,
            }}
            style={{ borderRadius: "25px" }}
            autoFocus={true}
            placeholder=""
            inputProps={{ maxLength: 64 }}
            value={sender}
            onChange={handleChangeGifter}
          />
        </Container>
        <Box pl={5} pr={5}>
          <Typography
            component="h4"
            variant="body1"
            fontWeight="bold"
            fontSize={19}
            sx={{ mb: 2 }}
            align="left"
          >
            <Trans>{t("giftDesignHint")}</Trans>
          </Typography>
        </Box>
        <Box pl={width > bp ? 5 : 0} pr={width > bp ? 5 : 0}>
          <Typography
            component="h4"
            variant="body1"
            fontWeight="bold"
            sx={{ mb: 2 }}
            align="center"
          >
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
              >
                <Box justifyContent="center" alignItems="center" sx={{ mb: 5 }}>
                  {img.map((img) => (
                    <FormControlLabel
                      key={img.id}
                      value={img.id}
                      control={
                        <Radio
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#67c2bb",
                              "& svg": {
                                width: "0.65em",
                                height: "0.65em",
                              },
                            },
                          }}
                        />
                      }
                      label={
                        <img
                          src={`${process.env.REACT_APP_IMAGEKIT_URL}${img.filename}`}
                          width="150px"
                          alt="WOLI GIFTCARD"
                          style={{ borderRadius: "15px" }}
                        />
                      }
                      onChange={handleChangeCard}
                    />
                  ))}
                </Box>
              </RadioGroup>
            </FormControl>
          </Typography>
        </Box>
        <Box pl={5} pr={5}>
          <Typography
            component="h4"
            variant="body1"
            fontWeight="bold"
            fontSize={19}
            sx={{ mb: 2 }}
            align="left"
          >
            <Trans>{t("giftMessageHint")}</Trans>
            <ThemeProvider theme={themeSourceSerifPro}>
              <Typography fontSize={14} display="inline">
                <Trans>{t("giftMessageHintParentheses")}</Trans>
              </Typography>
            </ThemeProvider>
          </Typography>
        </Box>
        <Container maxWidth="md">
          <TextField
            fullWidth={true}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "25px",

                  borderColor: "#67c2bb",
                },
                "&.Mui-focused fieldset": {
                  borderRadius: "25px",
                  borderColor: "#ff614e",
                },
                "&:hover fieldset": {
                  borderRadius: "25px",
                  borderColor: "#ff614e",
                },
              },
            }}
            style={{ borderRadius: "25px" }}
            placeholder="Happy Birthday from Grandpa"
            inputProps={{ maxLength: 180 }}
            onChange={handleChangeGifterDetails}
            value={details}
            multiline={true}
            rows={5}
          />
        </Container>
        <Box pl={5} pr={5}>
          <Typography
            fontSize={19}
            component="h4"
            variant="body1"
            fontWeight="bold"
            sx={{ mb: 2, mt: 4 }}
            align="left"
          >
            <Trans>{t("giftEmailHint")}</Trans>
            <ThemeProvider theme={themeSourceSerifPro}>
              <Typography fontSize={14} display="inline">
                <Trans>{t("giftEmailHintParentheses")}</Trans>
              </Typography>
            </ThemeProvider>
          </Typography>
        </Box>
        <Container maxWidth="md">
          <TextField
            fullWidth={true}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "25px",

                  borderColor: "#67c2bb",
                },
                "&.Mui-focused fieldset": {
                  borderRadius: "25px",
                  borderColor: "#ff614e",
                },
                "&:hover fieldset": {
                  borderRadius: "25px",
                  borderColor: "#ff614e",
                },
              },
            }}
            variant="outlined"
            style={{ borderRadius: "25px", borderColor: "#67c2bb" }}
            placeholder="e.g. email@provider.com"
            inputProps={{ maxLength: 180 }}
            value={email}
            onChange={handleChangeEmail}
          />
        </Container>
      </Stack>
    </Box>
  );
};

export default GiftDetails;
