import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          woliHero: "Send a Gift with Woli",
          woliGiftTitle1: "Choose and send a Gift Card to",
          woliGiftTitle2: "a Woli child in ",
          woliGiftTitle2Red: "3 simple steps",
          woliGiftTitleDetails1: "Select the amount, card design",
          woliGiftTitleDetails2: " and message for your gift",
          woliGiftTitleReview1: "Verify your gift",
          woliGiftTitleReview2: "and proceed to payment",
          uniqueGiftCode: "Enter the unique gift code",
          giftCodeHint:"Gift Code",
          giftCodeHintParentheses: " (Enter the 10-digit code that the Parent has shared with you)",
          giftCodeInvalidError: "The code is missing or is not valid.",
          giftCodeMemberError: "The member was not found.",
          giftCodeGenericError: "Generic Error.",
          backButton: "Back",
          continueButton: "Continue",
          giftDetailsTo: "Enter the details for your gift to:",
          giftAmountHint: "Gift amount",
          giftAmountHintParentheses: " (Max limit is 100€)",
          giftFromHint: "Gift is from",
          giftFromHintParentheses: " (Enter the name of the gifter)",
          giftDesignHint: "Choose your design",
          giftMessageHint: "Message",
          giftMessageHintParentheses: " (max 180 characters)",
          giftEmailHint: "Your e-mail",
          giftEmailHintParentheses: " (You will receive a transaction receipt)",
          giftAmountError: "Please select gift amount",
          giftDesignError: "Please select gift card design",
          giftSenderError: "Please enter the sender of the gift card",
          giftEmailError: "Please enter your email",
          giftEmailInvalidError: "Please enter a valid email",
          giftProceedPayment: "Proceed to payment",
          giftFrom: "From",
          giftTo: "To",
          parentsName: "Parent's Name",
          giftAmount: "Gift Amount",
          giftServiceFee: "Service Fee*",
          giftTotal: "Total",
          paymentWithCardButton: "Payment with card",
          appbarLan: "ΕΛ",
          success1: "Thank you for using the Woli Gift service.",
          success2: "We have sent a receipt to your email.",
          failure1: "The card transaction was not processed successfully.",
          failure2: "Please try again.",
          supportSuccess: "For any questions please contact support@woli.io",
          supportFailure: "For any assistance please contact support@woli.io",
          serviceFeeDisclaimer: "* A service fee is applied to cover transactional banking costs.",
          acceptTermsText: "By continuing you agree to Woli Gift",
        },
      },
      el: {
        translations: {
          woliHero: "Στείλε ένα δώρο με το Woli",
          woliGiftTitle1: "Διάλεξε και στείλε μια δωροκάρτα σε",
          woliGiftTitle2: "ένα Woli παιδί με ",
          woliGiftTitle2Red: "μόνο 3 βήματα",
          woliGiftTitleDetails1: "Επέλεξε το ποσό, το σχέδιο",
          woliGiftTitleDetails2: "και το μήνυμα για την δωροκάρτα",
          woliGiftTitleReview1: "Επαληθεύστε το δώρο",
          woliGiftTitleReview2: "και προχώρησε στην πληρωμή",
          uniqueGiftCode: "Συμπλήρωσε τον μοναδίκο κωδικό δώρου",
          giftCodeHint: "Κωδικός Δώρου",
          giftCodeHintParentheses: " (Εισήγαγε τον 10-φήφιο κωδικό που διαμοιράστηκε ο γονέας μαζί σου)",
          giftCodeInvalidError: "Ο κωδικός είναι κενός ή δεν είναι σωστός.",
          giftCodeMemberError: "Το μέλος δεν βρέθηκε.",
          giftCodeGenericError: "Γενικό σφάλμα.",
          backButton: "Πίσω",
          continueButton: "Συνέχεια",
          giftDetailsTo:
            "Εισήγαγε τις λεπτομέρειες για το δώρο σου στο(ν)/η(ν):",
          giftAmountHint: "Ποσό δώρου",
          giftAmountHintParentheses: " (Το μέγιστο όριο είναι 100€)",
          giftFromHint: "Το δώρο είναι από",
          giftFromHintParentheses: " (Συμπλήρωσε το όνομα του αποστολέα)",
          giftDesignHint: "Επίλεξε το σχέδιο",
          giftMessageHint: "Μήνυμα",
          giftMessageHintParentheses: " (μέγιστο 180 χαρακτήρες)",
          giftEmailHint: "Το email σου",
          giftEmailHintParentheses: " (Θα λάβεις την απόδειξη συναλλαγής)",
          giftAmountError: "Επίλεξε πόσο δωροκάρτας",
          giftDesignError: "Επίλεξε σχέδιο δωροκάρτας",
          giftSenderError: "Συμπλήρωσε τον αποστολέα της δωροκάρτας",
          giftEmailError: "Εισήγαγε το email σου",
          giftEmailInvalidError: "Εισήγαγε ένα έγκυρο email",
          giftProceedPayment: "Συνέχισε στην πληρωμή",
          giftFrom: "Από",
          giftTo: "Προς",
          parentsName: "Όνομα Γονέα",
          giftAmount: "Ποσό δωροκάρτας",
          giftServiceFee: "Έξοδα υπηρεσίας*",
          giftTotal: "Σύνολο",
          paymentWithCardButton: "Πληρωμή με κάρτα",
          appbarLan: "EN",
          success1: "Το Woli δώρο σου εστάλη!",
          success2: "Ευχαριστούμε που χρησιμοποίησες τo Woli Gift!",
          failure1: "Έχουμε στείλει την επιβεβαίωση πληρωμής στο email σου.",
          failure2: "Προσπάθησε ξανά.",
          supportSuccess:
            "Για ερωτήσεις παρακαλούμε επικοινώνησε με το support@woli.io",
          supportFailure:
            "Για οποιεσδήποτε βοήθεια παρακαλούμε επικοινωνήστε με την υποστήριξη support@woli.io",
          serviceFeeDisclaimer: "* Κόστος υπηρεσίας που καλύπτει τα έξοδα συναλλαγής",
          acceptTermsText: "Συνεχίζοντας αποδέχομαι το Woli Gift",
        },
      },
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: true, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
