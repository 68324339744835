import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Logo from "../assets/images/woli_gift_logo.png";
import Button from "@mui/material/Button";
import { default as LinkMUI } from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

export default function WoliAppbar() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [lng, setLng] = React.useState("");
  const { t, i18n } = useTranslation();
  
  const bp = 700;
  
  React.useEffect(() => {
    const handleWindowResizing = () => { setWidth(window.innerWidth); }
    
    window.addEventListener("resize", handleWindowResizing);
    return () => {
      window.removeEventListener("resize", handleWindowResizing);
    };
  }, []);

  const changeLanguage = (lng) => {
    if (lng === "en") {
      i18n.changeLanguage("en");
      setLng("el");
    } else {
      i18n.changeLanguage("el");
      setLng("en");
    }
  };

  return (
    <AppBar
      position="absolute"
      color="default"
      elevation={0}
      style={{ backgroundColor: "#ffffff" }}
      sx={{
        position: "relative",
      }}
    >
      <Toolbar>
        <Box display="flex" flexGrow={1} sx={{paddingTop: width > bp ? 0 : 3}}>
          <Link to="/">
            <Box
              component="img"
              sx={{
                height: width > bp ? 64 : 42.6,
                width: width > bp ? 178 : 118.6,
              }}
              alt="Woli Gift"
              src={Logo}
            />
          </Link>
        </Box>
        <LinkMUI
          href="https://www.woli.io/faq/"
          target="_blank"
          underline="none"
        >
          <Typography
            color="#ff614e"
            component="h3"
            variant="body1"
            fontWeight="bold"
            align="center"
            sx={{ pl:2.5 , pr: width > bp ? 5 : 3, mt: 3 }}
          >
            FAQ
          </Typography>
        </LinkMUI>
        <LinkMUI href="#" underline="none" >
          <Typography
            onClick={() => changeLanguage(lng)}
            color="black"
            component="h3"
            variant="body1"
            fontWeight="bold"
            align="center"
            sx={{pr: width > bp ? 5 : 3, mt: 3 }}
          >
            <Trans>{t("appbarLan")}</Trans>
          </Typography>
        </LinkMUI>
        <Button
          onClick={() => window.open("https://www.woli.io/", "_blank")}
          variant="contained"
          style={{ borderRadius: "25px" }}
          sx={{
            mt: 3,
            mr: 2,
            backgroundColor: "black",
            "&:hover": {
              backgroundColor: "#ff614e",
            },
          }}
        >
          {width > bp ? "Go to woli.io" : "woli.io"} 
        </Button>
      </Toolbar>
    </AppBar>
  );
}
