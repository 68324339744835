import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useTranslation, Trans } from "react-i18next";
import { themeSourceSerifPro } from "../themeConstants";
import { ThemeProvider } from "@mui/material/styles";

const GiftOrderReview = (props) => {
  const { t } = useTranslation();

  const [width, setWidth] = React.useState(window.innerWidth);

  const bp = 800;

  useEffect(() => {
    const handleWindowResizing = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResizing);
    return () => {
      window.removeEventListener("resize", handleWindowResizing);
    };
  }, []);

  const { gifter, first, last, price, parent, fee, img, imgInd } = props;
  // eslint-disable-next-line
  var imgUrl = img.find((o) => o.id == imgInd);

  const imgUrlAppend = imgUrl.filename;

  const rowsPersonalInfo = [
    createData(`${t("giftFrom")}`, `${gifter}`),
    createData(`${t("giftTo")}`, `${first} ${last}`),
    createData("", `(${t("parentsName")}: ${parent})`),
  ];

  const rowsPrices = [
    createData(`${t("giftAmount")}`, `${price} €`),
    createData(`${t("giftServiceFee")}`, `${fee} €`),
  ];

  const rowTotal = [
    createData(`${t("giftTotal")}`, `${parseInt(price) + parseInt(fee)} €`),
  ];

  function createData(name, data) {
    return { name, data };
  }
  return (
    <Box pl={5} pr={5}>
      <Stack>
        <ThemeProvider theme={themeSourceSerifPro}>
          <Typography
            component="h3"
            variant="h6"
            fontSize={20}
            fontWeight="bold[500]"
            sx={{ mb: 6 }}
            align="center"
          >
            {t("giftProceedPayment")}
          </Typography>
        </ThemeProvider>
        <Box sx={{ mt: 10 }} align="center">
          <Box
            pl={2}
            pr={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Paper
              elevation={0}
              style={{ borderRadius: "45px" }}
              sx={{
                width: width > bp ? 550 : 365,
                position: "relative",
                backgroundColor: "#e8fafa",
                color: "#fff",
                mb: 4,
              }}
            >
              <img
                src={`${process.env.REACT_APP_IMAGEKIT_URL}${imgUrlAppend}`}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  marginTop: -80,
                  width: width > bp ? 420 : 320,
                  borderRadius: "15px",
                }}
                alt={`WOLI GIFTCARD NUMBER ${imgInd}`}
              />
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      position: "relative",
                      p: { xs: 2, md: 2 },
                      //pr: { md: 0 },
                    }}
                  >
                    <TableContainer
                      component={Paper}
                      elevation={0}
                      sx={{
                        backgroundColor: "#e8fafa",
                        color: "#fff",
                        mb: 4,
                      }}
                    >
                      <Table
                        sx={{
                          width: 330,
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                        }}
                        aria-label="simple table"
                      >
                        <TableBody>
                          {rowsPersonalInfo.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th":
                                  {
                                    borderBottom: 1,
                                  },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ padding: "0px 0px 0px 0px" }}
                              >
                                <ThemeProvider theme={themeSourceSerifPro}>
                                  <Typography
                                    component="h3"
                                    variant="body1"
                                    fontSize={19}
                                  >
                                    {row.name}
                                  </Typography>
                                </ThemeProvider>
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ padding: "0px 0px 0px 0px" }}
                              >
                                <ThemeProvider theme={themeSourceSerifPro}>
                                  <Typography
                                    component="h3"
                                    variant="body1"
                                    size="small"
                                    fontSize={19}
                                    sx={
                                      row.name === ""
                                        ? { fontSize: 14, color: "grey" }
                                        : ""
                                    }
                                  >
                                    {row.data}
                                  </Typography>
                                </ThemeProvider>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Table
                        sx={{
                          width: 330,
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                        }}
                        aria-label="simple table"
                      >
                        <TableBody>
                          {rowsPrices.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  borderBottom: 1,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ padding: "0px 0px 0px 0px" }}
                              >
                                <Typography
                                  component="h3"
                                  variant="body1"
                                  fontWeight="bold"
                                  fontSize={19}
                                >
                                  {row.name}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ padding: "0px 0px 0px 0px" }}
                              >
                                <Typography
                                  component="h3"
                                  variant="body1"
                                  fontWeight="bold"
                                  fontSize={19}
                                >
                                  {row.data}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableBody>
                          {rowTotal.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ padding: "0px 0px 0px 0px" }}
                              >
                                <Typography
                                  component="h3"
                                  variant="body1"
                                  fontWeight="bold"
                                  fontSize={19}
                                >
                                  {row.name}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ padding: "0px 0px 0px 0px" }}
                              >
                                <Typography
                                  component="h3"
                                  variant="body1"
                                  fontWeight="bold"
                                  fontSize={19}
                                >
                                  {row.data}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <ThemeProvider theme={themeSourceSerifPro}>
            <Typography
              style={{ marginTop: -25, fontSize: 12, color: "#67c2bb" }}
            >
              <Trans>{t("serviceFeeDisclaimer")}</Trans>
            </Typography>
          </ThemeProvider>
        </Box>
      </Stack>
    </Box>
  );
};

export default GiftOrderReview;
