import WoliStepper from "../components/WoliStepper";
import WoliHero from "../components/WoliHero";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import * as React from "react";

export default function WoliMain() {
  return (
    <div>
      <WoliHero />
      <Container component="main" maxWidth="md">
        <Paper elevation={0}>
          <WoliStepper />
        </Paper>
      </Container>
    </div>
  );
}
