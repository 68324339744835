import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Container } from "@mui/material";
import { themeSourceSerifPro } from "../themeConstants";
import { ThemeProvider } from "@mui/material/styles";

const UniqueGiftCode = (props) => {
  const { t } = useTranslation();

  const [width, setWidth] = React.useState(window.innerWidth);

  const bp = 800;

  useEffect(() => {
    const handleWindowResizing = () => { setWidth(window.innerWidth); }
    
    window.addEventListener("resize", handleWindowResizing);
    return () => {
      window.removeEventListener("resize", handleWindowResizing);
    };
  }, []);

  const handleChange = (e) => {
    props.onChange(e.target.value);
  };
  return (
    <Box pl={1} pr={1}>
      <Stack>
        <ThemeProvider theme={themeSourceSerifPro}>
          <Typography component="h3" variant="h6" fontSize={22} sx={{ mb: 5 }} align="center">
            <Trans>{t("uniqueGiftCode")}</Trans>
          </Typography>
        </ThemeProvider>
        <Typography component="h4" variant="body1" fontSize={19} sx={{ mb: 2 }} align="center">
            <Trans>{t("giftCodeHint")}</Trans><ThemeProvider theme={themeSourceSerifPro}><Typography fontSize={14} display="inline"><Trans>{t("giftCodeHintParentheses")}</Trans></Typography></ThemeProvider>
        </Typography>
        <Container maxWidth={width > bp ? "sm" : "xs"}>
          <TextField
          fullWidth={true}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "25px",
                borderColor: "#67c2bb",
              },
              "&.Mui-focused fieldset": {
                borderRadius: "25px",
                borderColor: "#ff614e",
              },
              "&:hover fieldset": {
                borderRadius: "25px",
                borderColor: "#ff614e",
              },
            },
          }}
          style={{ borderRadius: "25px" }}
          autoFocus={true}
          placeholder="e.g. 0123456789"
          inputProps={{ maxLength: 10 }}
          onChange={handleChange}
          />
        </Container>
      </Stack>
    </Box>
  );
};

export default UniqueGiftCode;
