import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import UniqueGiftCode from "./UniqueGiftCode";
import axios from "axios";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import GiftDetails from "./GiftDetails";
import GiftOrderReview from "./GiftOrderReview";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import isEmail from "validator/lib/isEmail";
import { useTranslation, Trans } from "react-i18next";
import { themeSourceSerifPro } from "../themeConstants";
import { ThemeProvider } from "@mui/material/styles";
import AcquiredImage from "../assets/images/Acquired.png";
import Grid from "@mui/material/Grid";
import { default as LinkMUI } from "@mui/material/Link";
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

function getStepContent(step) {
  switch (step) {
    case 0:
      return <UniqueGiftCode onChange={onChangeCode} />;
    case 1:
      console.log(sender);
      return (
        <GiftDetails
          first={firstName}
          last={lastName}
          img={images}
          gifter={sender}
          imgInd={design}
          message={details}
          eMail={email}
          price={amount}
          onChangeGifterDetails={onChangeDetails}
          onChangeGifter={onChangeSender}
          onChangeCard={onChangeDesign}
          onChangePrice={onChangeAmount}
          onChangeEmail={onChangeEmail}
        />
      );
    case 2:
      return (
        <GiftOrderReview
          gifter={sender}
          first={firstName}
          last={lastName}
          parent={parentOfficialName}
          price={amount}
          fee={fees}
          img={images}
          imgInd={design}
        />
      );
    default:
      throw new Error("Unknown step");
  }
}

var code = 0;
var firstName;
var lastName;
var images;
var parentOfficialName;
var design = null;
var sender;
var details;
var fees;
var amount = null;
var email;

function onChangeCode(value) {
  code = value;
}
function onChangeDetails(value) {
  details = value;
}
function onChangeSender(value) {
  sender = value;
  console.log(sender);
}
function onChangeDesign(value) {
  design = value;
}
function onChangeAmount(value) {
  amount = value;
}
function onChangeEmail(value) {
  email = value;
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#67c2bb",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#67c2bb",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const steps = ["Unique Gift Code", "Gift Details", "Address / Confirm"];

export default function WoliStepper() {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [codeError, setCodeError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const sendDataToGTM = useGTMDispatch();

  const [width, setWidth] = React.useState(window.innerWidth);

  const bp = 800;

  useEffect(() => {
    const handleWindowResizing = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResizing);
    return () => {
      window.removeEventListener("resize", handleWindowResizing);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      //behavior: "smooth",
    });
  }, [activeStep]);

  const handleNext = () => {
    setCodeError("");

    if (activeStep === 0) {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}`, {
          jsonrpc: "2.0",
          id: 1,
          method: "Gift.getMember",
          params: {
            code: parseInt(code),
          },
        })
        .then(function (response) {
          firstName = response.data.result.data.firstName;
          lastName = response.data.result.data.lastName;
          parentOfficialName = response.data.result.data.parentOfficialName;
          axios
            .post(`${process.env.REACT_APP_BACKEND_URL}`, {
              jsonrpc: "2.0",
              id: 2,
              method: "Gift.getGiftCards",
            })
            .then(function (res) {
              images = res.data.result.data.images;
              if (
                response.data.result.status === "ok" &&
                res.data.result.status === "ok"
              ) {
                setLoading(false);
                sendDataToGTM({ event: 'giftCodeNextPressedSuccess', value: code });
                setActiveStep(activeStep + 1);
              } else {
                switch (response.data.result.data.type) {
                  case "invalid_field_code":
                    sendDataToGTM({ event: 'giftCodeNextPressedError', value: "giftCodeInvalidFieldError" });
                    setCodeError("*" + t("giftCodeInvalidError"));
                    break;
                  case "member_not_found":
                    sendDataToGTM({ event: 'giftCodeNextPressedError', value: "giftCodeMemberNotFound" });
                    setCodeError("*" + t("giftCodeMemberError"));
                    break;
                  default:
                    sendDataToGTM({ event: 'giftCodeNextPressedError', value: "giftCodeGenericError" });
                    setCodeError("*" + t("giftCodeGenericError"));
                }
                setLoading(false);
              }
            })
            .catch(function (err) {
              console.log(err);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    if (activeStep === 1) {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}`, {
          jsonrpc: "2.0",
          id: 2,
          method: "Payment.getGiftFees",
          params: {
            code: parseInt(code),
            amount: 100,
          },
        })
        .then(function (res) {
          fees = res.data.result.data.fees;
          if (res.status === 200) {

            if (amount == null) {
              sendDataToGTM({ event: 'giftCardDetailsNextPressedError', value: "giftAmountError" });
              setCodeError("*" + t("giftAmountError"));
              setLoading(false);
            } else if (sender == null) {
              sendDataToGTM({ event: 'giftCardDetailsNextPressedError', value: "giftSenderError" });
              setCodeError("*" + t("giftSenderError"));
              setLoading(false);
            } else if (design == null) {
              sendDataToGTM({ event: 'giftCardDetailsNextPressedError', value: "giftDesignError" });
              setCodeError("*" + t("giftDesignError"));
              setLoading(false);
            } else if (email == null) {
              sendDataToGTM({ event: 'giftCardDetailsNextPressedError', value: "giftEmailError" });
              setCodeError("*" + t("giftEmailError"));
              setLoading(false);
            } else if (!isEmail(email, { allow_utf8_local_part: false })) {
              sendDataToGTM({ event: 'giftCardDetailsNextPressedError', value: "giftEmailError" });
              setCodeError("*" + t("giftEmailInvalidError"));
              setLoading(false);
            } else {
              setLoading(false);
              if (details == null) {
                details = "-";
              }
              sendDataToGTM({ event: 'giftCardDetailsNextPressedSuccess', value: "userGoesToCheckout" });
              sendDataToGTM({ event: 'giftCardDetailsAmount', value: amount });
              sendDataToGTM({ event: 'giftCardDetailsSender', value: sender });
              sendDataToGTM({ event: 'giftCardDetailsDesign', value: design });
              sendDataToGTM({ event: 'giftCardDetailsEmail', value: email });
              sendDataToGTM({ event: 'giftCardDetailsMessage', value: details });
              setActiveStep(activeStep + 1); 
            }
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
    if (activeStep === 2) {
      sendDataToGTM({ event: 'userClickAcquiredPayment', value: 'userRedirectedToPaymentPortal' });
      var url = `${process.env.REACT_APP_BACKEND_URL_PAYMENT
        }?code=${code}&gift_card=${design}&amount=${parseInt(amount)}
      &note=${details}&tag=gift&sender=${sender}&email=${email}`;
      window.open(url, "_self", "noopener,noreferrer");
    }
  };

  const handleBackStepper = (step) => () => {
    console.log(step);
    sendDataToGTM({ event: 'stepperButtonClicked', value: `page: ${step}` });
    setActiveStep(step);
    console.log(activeStep);
    if (step + 1 === 1) {
      code = null;
      amount = sender = design = email = details = null;
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    sendDataToGTM({ event: 'stepperButtonClicked', value: `page: ${activeStep}` });
    if (activeStep === 1) {
      code = null;
      amount = sender = design = email = details = null;
    }
  };

  return (
    <Stack sx={{ width: "100%" }} spacing={width > bp ? 4 : 1}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {activeStep === 0 ? (
        <div>
          <Typography
            component="h2"
            variant="h6"
            fontSize={25}
            fontWeight="bold"
            align="center"
          >
            <Trans>{t("woliGiftTitle1")}</Trans>
          </Typography>
          <Typography
            component="h2"
            variant="h6"
            fontSize={25}
            fontWeight="bold"
            align="center"
            sx={{ pb: width > bp ? 5 : 1 }}
          >
            <Trans>
              {t("woliGiftTitle2")}
              <span style={{ color: "#ff614e" }}>{t("woliGiftTitle2Red")}</span>
            </Trans>
          </Typography>
        </div>
      ) : activeStep === 1 ? (
        <div>
          <Typography
            component="h2"
            variant="h6"
            fontSize={25}
            fontWeight="bold"
            align="center"
          >
            <Trans>{t("woliGiftTitleDetails1")}</Trans>
          </Typography>
          <Typography
            component="h2"
            variant="h6"
            fontSize={25}
            fontWeight="bold"
            align="center"
            sx={{ pb: width > bp ? 5 : 1 }}
          >
            <Trans>{t("woliGiftTitleDetails2")}</Trans>
          </Typography>
        </div>
      ) : (
        <div>
          <Typography
            component="h2"
            variant="h6"
            fontSize={25}
            fontWeight="bold"
            align="center"
          >
            <Trans>{t("woliGiftTitleReview1")}</Trans>
          </Typography>
          <Typography
            component="h2"
            variant="h6"
            fontSize={25}
            fontWeight="bold"
            align="center"
            sx={{ pb: width > bp ? 5 : 1 }}
          >
            <Trans>{t("woliGiftTitleReview2")}</Trans>
          </Typography>
        </div>
      )}
      <Stepper activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map((label, index) => (
          <Step sx={{paddingLeft:'15px', paddingRight:0}} key={label}>
            <StepButton
              sx={[
                { "& .MuiStepIcon-root.Mui-active": { color: "#ff614e" } },
                { "& .MuiStepIcon-root.Mui-completed": { color: "#ff614e" } },
                { "& .MuiStepIcon-root": { color: "black" } },
                { padding: '0px 0px 0px 0px' }
              ]}
              onClick={handleBackStepper(index)}
            ></StepButton>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {getStepContent(activeStep)}
        <ThemeProvider theme={themeSourceSerifPro}>
          <Typography
            component="h4"
            variant="body1"
            fontWeight="light"
            color="red"
            sx={{ mt: 0 }}
            align="center"
          >
            {codeError}
          </Typography>
        </ThemeProvider>
        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justifyContent={activeStep === 0 ? "center" : "space-between"}
              alignItems="center"
            >
              {activeStep !== 0 && (
                <Button
                  onClick={handleBack}
                  sx={{ mt: 1, ml: 1, color: "#67c2bb" }}
                >
                  <Trans>{t("backButton")}</Trans>
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleNext}
                style={{ borderRadius: "25px", textTransform: "none" }}
                startIcon={<ArrowCircleRightOutlinedIcon />}
                sx={{
                  mt: 1,
                  ml: 1,
                  mr: activeStep === 0 ? 0 : 10,
                  backgroundColor: "#ff614e",
                  "&:hover": {
                    backgroundColor: "#ff614e",
                  },
                }}
              >
                <span style={{ paddingLeft: 20, paddingRight: 20 }}>
                  {activeStep === steps.length - 1 ? (
                    <Trans>{t("paymentWithCardButton")}</Trans>
                  ) : (
                    <Trans>{t("continueButton")}</Trans>
                  )}
                </span>
              </Button>
              {activeStep !== 0 && <div></div>}
            </Grid>
            <br></br>
            {activeStep === steps.length - 1 ? (
              <img
                src={AcquiredImage}
                style={{
                  width: width > bp ? "30%" : "50%",
                  alignSelf: "center",
                }}
                alt="Acquired Safe Payment Gateway logo"
              />
            ) : (
              <p></p>
            )}
            {activeStep === steps.length - 1 ? (
              <ThemeProvider theme={themeSourceSerifPro}>
                <Typography
                  fontSize={15}
                  style={{ paddingTop: 35, paddingBottom: 35 }}
                >
                  <Trans>{t("acceptTermsText")}</Trans>
                  <LinkMUI
                    href="https://www.woli.io/terms-and-conditions-for-woli-gift/"
                    target="_blank"
                    underline="always"
                    style={{ color: "black", marginLeft: 5 }}
                  >
                    terms of service
                  </LinkMUI>
                </Typography>
              </ThemeProvider>
            ) : (
              <p></p>
            )}
          </Grid>
        </Box>
      </React.Fragment>
    </Stack>
  );
}
