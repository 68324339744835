import { createTheme } from "@mui/material/styles";
import ApercuProBoldWoff2 from './fonts/ApercuPro-Bold.woff2'

export const themeApercu = createTheme({
  typography: {
    fontFamily: ['Apercu Pro'].join(',')
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
          font-family: 'Apercu Pro';
          font-style: bold;
          font-display: swap;
          font-weight: 400;
          src: local('Apercu Pro'), local('Apercu Pro'), url(${ApercuProBoldWoff2}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
    }
  }
});

export const themeSourceSerifPro = createTheme({
  typography: {
    fontFamily: ['Source Serif Pro', 'serif'].join(','),
  }
});