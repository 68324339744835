import * as React from "react";
import { themeSourceSerifPro, themeApercu } from "../themeConstants";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import "./footer.css";

export default function WoliFooter() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <ThemeProvider theme={themeSourceSerifPro}>
      <div>
        <footer id="footer">
          <div class="max-width">
            <div class="footer__top">
              <div>
                <div class="footer__contact">
                  <div class="footer__contact__title h3">Contact us</div>
                  <div class="footer__contact__links">
                    <div class="footer__contact__link">
                      <span>e.</span>
                      <a href="mailto:support@woli.io" target="_blank">
                        support@woli.io
                      </a>
                    </div>
                    <div class="footer__contact__link">
                      <span>t.</span>
                      <div>Woli app chat</div>
                    </div>
                  </div>
                </div>
                <div class="footer__social social">
                  <a href="https://www.facebook.com/woli.gr" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="#FFF"
                        d="M13.227 15.056v6.602c0 .096.078.17.172.17h2.452a.171.171 0 0 0 .174-.17v-6.712h1.777a.167.167 0 0 0 .169-.157l.173-2.021a.17.17 0 0 0-.173-.188h-1.946v-1.434c0-.337.271-.607.607-.607h1.371c.096 0 .172-.08.172-.175V8.339a.17.17 0 0 0-.172-.168h-2.318a2.458 2.458 0 0 0-2.458 2.46v1.948h-1.229a.172.172 0 0 0-.172.172v2.021c0 .094.078.173.172.173h1.229v.111z"
                      ></path>
                    </svg>
                  </a>
                  <a href="https://twitter.com/AppWoli" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <path
                        fill="#FFF"
                        d="M21.592 10.842c-.423.19-.888.306-1.345.384.218-.037.534-.428.664-.587a2.43 2.43 0 0 0 .446-.813c.01-.025.021-.054-.004-.07-.027-.012-.047-.006-.068.006a6.864 6.864 0 0 1-1.594.609c-.051.011-.083 0-.111-.027-.048-.053-.091-.101-.14-.142a2.76 2.76 0 0 0-.771-.476 2.717 2.717 0 0 0-1.183-.191 2.794 2.794 0 0 0-1.127.319 3.025 3.025 0 0 0-.904.737 2.772 2.772 0 0 0-.542 1.086 2.906 2.906 0 0 0-.028 1.145c.01.064.004.073-.053.064-2.191-.326-4.005-1.108-5.477-2.788-.064-.071-.1-.071-.152.006-.644.966-.332 2.519.473 3.28.109.103.219.204.338.299-.043.006-.582-.053-1.055-.299-.065-.04-.098-.018-.105.056a1.89 1.89 0 0 0 .019.313c.126.979.802 1.887 1.731 2.24.111.045.229.085.352.106-.213.048-.43.079-1.039.032-.074-.017-.104.023-.074.096.457 1.241 1.439 1.611 2.179 1.823.1.018.196.018.294.04-.002.01-.01.01-.016.018-.244.373-1.093.649-1.488.789a5.362 5.362 0 0 1-2.259.288c-.121-.018-.146-.018-.177 0-.035.021-.006.048.031.081.156.102.313.19.471.279.483.256.986.459 1.512.602 2.717.75 5.768.202 7.806-1.823 1.599-1.591 2.163-3.782 2.163-5.978 0-.088.101-.133.157-.178.418-.313.751-.687 1.058-1.105.071-.093.07-.178.07-.21v-.012c-.001-.033 0-.022-.052.001"
                      ></path>
                    </svg>
                  </a>
                  <a href="https://www.instagram.com/woli_app/" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <path
                        fill="#FFF"
                        d="M20.849 17.739a2.947 2.947 0 0 1-2.946 2.948h-5.667a2.948 2.948 0 0 1-2.947-2.948v-5.663a2.947 2.947 0 0 1 2.947-2.946h5.667a2.946 2.946 0 0 1 2.946 2.946v5.663zm1.347-5.663a4.294 4.294 0 0 0-4.294-4.293h-5.667a4.292 4.292 0 0 0-4.291 4.293v5.663a4.293 4.293 0 0 0 4.291 4.293h5.667a4.294 4.294 0 0 0 4.294-4.293v-5.663zm-7.103 5.18A2.259 2.259 0 0 1 12.839 15a2.26 2.26 0 0 1 2.254-2.255A2.259 2.259 0 0 1 17.347 15a2.257 2.257 0 0 1-2.254 2.256m0-5.774A3.522 3.522 0 0 0 11.575 15a3.522 3.522 0 0 0 3.518 3.518A3.524 3.524 0 0 0 18.614 15a3.526 3.526 0 0 0-3.521-3.518m3.724-1.075a.841.841 0 0 0-.84.836c0 .464.381.839.84.839a.836.836 0 1 0 0-1.675"
                      ></path>
                    </svg>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/woli-fintech"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <path
                        fill="#FFF"
                        d="M13.018 20.759l.002-8.855c0-.174 0-.174.172-.174.793 0 1.584.002 2.376-.002.109-.001.15.016.146.139-.011.321-.003.643-.003.964v.124c.052.011.056-.023.065-.043.474-.788 1.188-1.223 2.078-1.359.734-.113 1.467-.051 2.162.238.783.325 1.232.945 1.477 1.734.14.451.199.917.238 1.387.002.03-.021.072.026.091v.079c-.022.024-.01.055-.014.082v.259c.005.036-.013.076.014.109 0 1.706-.001 3.415.003 5.122 0 .091-.018.109-.107.107-.897-.004-1.797-.002-2.694-.002-.056-.068-.039-.15-.039-.229 0-1.514.004-3.027-.004-4.541-.002-.391-.039-.781-.173-1.156-.189-.533-.585-.781-1.133-.818a1.992 1.992 0 0 0-.678.074c-.502.143-.783.496-.93.977a3.715 3.715 0 0 0-.145 1.082c-.006 1.461-.002 2.922-.004 4.384 0 .077.018.159-.039.229-.93-.002-1.863-.002-2.796-.002zM8.449 20.759c.004-.053.011-.104.011-.155.001-2.904.001-5.81-.003-8.712 0-.136.033-.165.165-.163.831.006 1.663.005 2.495 0 .117 0 .152.025.152.149-.004 2.908-.003 5.816-.003 8.725 0 .053.006.104.009.157l-2.826-.001zM10.061 7.239c.17.047.344.081.505.159a1.636 1.636 0 0 1 .886 1.799 1.64 1.64 0 0 1-1.578 1.304c-.736.01-1.405-.517-1.587-1.244-.014-.048-.003-.106-.049-.144v-.369c.027-.042.008-.09.014-.134-.003-.293.125-.537.299-.756.168-.211.371-.384.619-.5.15-.071.309-.09.468-.114l.423-.001z"
                      ></path>
                      <path
                        fill="#FFF"
                        d="M8.238 8.612c.008.001.016 0 .023-.004.009.048.014.096-.023.136v-.132zM21.734 15.423v-.105c.009-.01.018-.011.023.002v.105c-.007.012-.015.012-.023-.002z"
                      ></path>
                      <path
                        fill="#FFF"
                        d="M21.734 15.423l.023.002v.104c-.038-.028-.023-.068-.023-.106zM21.736 15.159c.002-.027-.023-.063.021-.078v.079c-.006.013-.013.012-.021-.001z"
                      ></path>
                      <path
                        fill="#FFF"
                        d="M21.736 15.159l.021.001v.054c-.008.012-.015.012-.021-.002v-.053z"
                      ></path>
                      <path
                        fill="#FFF"
                        d="M21.736 15.212l.021.002v.053c-.008.012-.016.012-.021-.002v-.053z"
                      ></path>
                      <path
                        fill="#FFF"
                        d="M21.736 15.265l.021.002v.053a.164.164 0 0 1-.023-.002l.002-.053z"
                      ></path>
                    </svg>
                  </a>
                  <a href="https://www.youtube.com/@woli_app" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <path
                        fill="#FFF"
                        d="M12.765 18.727v-7.453L19.236 15c-2.166 1.246-4.301 2.477-6.471 3.727z"
                      ></path>
                    </svg>
                  </a>
                  <a href="https://www.tiktok.com/@woli_app" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <path
                        class="st0"
                        fill="#fff"
                        d="M20.2,11.4c-1.4-0.3-2.4-1.5-2.5-2.9V8.2h-2.3v9.2c0,1.1-0.9,2-2,1.9c-0.6,0-1.2-0.3-1.5-0.8l0,0l0,0
	c-0.7-0.9-0.5-2.1,0.4-2.7c0.5-0.4,1.2-0.5,1.8-0.3v-2.4c-2.3-0.3-4.5,1.3-4.8,3.6c-0.2,1.3,0.3,2.6,1.2,3.6c1.6,1.7,4.4,1.7,6,0.1
	c0.8-0.8,1.3-1.9,1.3-3.1v-4.7c0.9,0.7,2.1,1,3.2,1v-2.3C20.7,11.5,20.4,11.5,20.2,11.4z"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div class="footer__smallmenu">
                  <ul>
                    <li class=" menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-303">
                      <a href="https://www.woli.io/legal-documents/">
                        Legal Documents
                      </a>
                    </li>
                    <li class=" menu-item menu-item-type-post_type menu-item-object-page menu-item-314">
                      <a href="https://www.woli.io/legal-documents/privacy-policy/">
                        Website Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div class="footer__mastercard">
                  <div class="h5">In partnership with</div>
                  <img
                    src="https://www.woli.io/wp-content/themes/vg-twig/assets/img/visa.png"
                    alt="VISA"
                  />
                </div>
              </div>
              <div>
                <div class="footer__menu">
                  <ul>
                    <li class=" menu-item menu-item-type-post_type menu-item-object-page menu-item-1020">
                      <a href="https://www.woli.io/faq/" class="h5">
                        FAQs
                      </a>
                    </li>
                    <li class=" menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-1024">
                      <a href="https://www.woli.io/legal-documents/" class="h5">
                        Legal Documents
                      </a>
                    </li>
                    <li class=" menu-item menu-item-type-post_type menu-item-object-page menu-item-61">
                      <a href="https://www.woli.io/about/" class="h5">
                        About us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="footer__lower">
            <div class="max-width">
              <div class="footer__lower__title">
                <span>&nbsp;</span>
                <span class="arrow" onClick={scrollToTop}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16.334 12.587l-4.333 4.334-4.335-4.334 1.241-1.207 2.192 2.158V7.079h1.802v6.459l2.192-2.158 1.241 1.207z"></path>
                  </svg>
                </span>
              </div>
              <div class="footer__lower__text">
                <div>
                  <p>
                    Copyright © 2023 Woli Services Ltd. All rights reserved.
                  </p>
                  <p>
                    Woli Services LTD, a company with its head office at:
                    Mnasiadou &amp; Strasikratous 10 ELMA HOUSE, 2nd Floor, 1065
                    Nicosia Cyprus, company number: HE411043, provides you with
                    the Woli mobile app.
                  </p>
                  <p>
                    Woli Services LTD is acting on behalf of Paynetics AD, as
                    it’s registered agent (for the territory of Republic of
                    Cyprus) and subcontractor (for the territory of the other EU
                    member states), and is entered into the public register of
                    e-money institutions’ agents kept by the Bulgarian National
                    Bank.
                  </p>
                  <p>
                    Paynetics AD is a company incorporated in the Republic of
                    Bulgaria, company number: 131574695, with its head office
                    at: 76A James Bouchier Blvd., Sofia, Republic of Bulgaria.
                    Paynetics AD is an Electronic Money Institution (“EMI”)
                    which is authorised by the Bulgarian National Bank under the
                    Law on Payment Services and Payment Systems (Resolution
                    44/11.04.2016 of the Bulgarian National Bank) for issuing,
                    distribution, and redemption of electronic money and for
                    provision of payment services. Paynetics AD is passported to
                    provide e-money and payment services on the territory of the
                    EU and the EEA. The e-money accounts and the cards
                    associated to them are set up and issued through the Woli
                    mobile app by Paynetics AD, which is also a principal member
                    of Visa and MasterCard.
                  </p>
                  <p>
                    Woli Operations Single Member P.C. a company incorporated in
                    Greece, is a subsidiary of Woli Services LTD, with
                    registered company number 801473245 and and General
                    Commercial Registry (G.E.MI) 157569501000
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </ThemeProvider>
  );
}
