import WoliHero from "../components/WoliHero";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import GiftBox from "../assets/images/gift-box.png";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { useTranslation, Trans } from "react-i18next";
import Lottie from "lottie-react";
import animationData from "../lottie/17375-sparkles.json"
import { themeSourceSerifPro } from "../themeConstants";
import { ThemeProvider } from "@mui/material/styles";
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

export default function WoliSuccess() {
  const { t } = useTranslation();
  const [width, setWidth] = React.useState(window.innerWidth);
  const sendDataToGTM = useGTMDispatch();
  
  const bp = 700;

  
  React.useEffect(() => {
    const handleWindowResizing = () => { setWidth(window.innerWidth); }
    sendDataToGTM({ event: 'giftCardPayment', value: 'success' });
    
    window.addEventListener("resize", handleWindowResizing);
    return () => {
      window.removeEventListener("resize", handleWindowResizing);
    };
  }, []);
  
  const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

  return (
    <div>
      <WoliHero />
      <Container component="main" maxWidth="lg" sx={{ mb: 4, mt: 5 }}>
        <Box>
          <Paper
            elevation={0}
            style={{ borderRadius: "45px" }}
            sx={{
              position: "relative",
              backgroundColor: "#e8fafa",
              color: "#fff",
              mb: 4,

              backgroundSize: "contain",
              backgroundRepeat: "repeat-y",
              backgroundPosition: "center 5px",
            }}
          >
            <Grid container direction="column"
              justifyContent="center"
              alignItems="center">
              <Grid item xs={12}>
                <Box
                  sx={{
                    position: "relative",
                    p: { xs: 8, md: 15 },
                  }}
                >
                  <img
                    src={GiftBox}
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                      marginTop: -0,
                      marginBottom: 5,
                      width: 250,
                    }}
                    alt="GIFT BOX"
                  />
                  <Lottie
                    animationData={animationData}
                    loop={true}
                    style={{
                      marginTop: width > bp ? -400 : -300,
                      marginBottom: width > bp ? 0 : 150
                    }}
                  />
                  <Typography
                    fontWeight="bold"
                    align="center"
                    component="h1"
                    variant="h4"
                    color="black"
                    gutterBottom
                  >
                    <Trans>{t("success1")}</Trans>
                  </Typography>
                  <ThemeProvider theme={themeSourceSerifPro}>
                  <Typography
                    align="center"
                    component="h1"
                    variant="body1"
                    color="black"
                    gutterBottom
                    sx={{ marginTop: 10 }}
                  >
                    <Trans>{t("success2")}</Trans>
                  </Typography>
                  <Typography
                    align="center"
                    component="h1"
                    variant="body2"
                    color="black"
                    gutterBottom
                  >
                    <Trans>{t("supportSuccess")}</Trans>
                    </Typography>
                  </ThemeProvider>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}
