import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import WoliMain from "./pages/WoliMain";
import WoliSuccess from "./pages/WoliSuccess";
import WoliFailure from "./pages/WoliFailure";
import WoliAppbar from "./components/WoliAppbar";
import WoliFooter from "./components/WoliFooter";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import { themeApercu } from "./themeConstants.js";
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'

export default function Checkout() {

  const gtmParams = { id: `${process.env.REACT_APP_GTM_ID}` }

  return (
    <div>
      <GTMProvider state={gtmParams}>
        <ThemeProvider theme={themeApercu}>
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              //minHeight: "100vh",
            }}
          >
            <WoliAppbar />
            <Routes>
              <Route exact path="/success" element={<WoliSuccess />} />
              <Route exact path="/failure" element={<WoliFailure />} />
              <Route exact path="/" element={<WoliMain />} />
            </Routes>
          </Box>
        </ThemeProvider>
        <WoliFooter />
      </GTMProvider>
    </div>
  );
}
