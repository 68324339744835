import WoliHero from "../components/WoliHero";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Bell from "../assets/images/bell.png";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { useTranslation, Trans } from "react-i18next";
import { themeSourceSerifPro } from "../themeConstants";
import { ThemeProvider } from "@mui/material/styles";
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

export default function WoliFailure() {
  const { t } = useTranslation();
  const [width, setWidth] = React.useState(window.innerWidth);
  const sendDataToGTM = useGTMDispatch();

  React.useEffect(() => {
    const handleWindowResizing = () => { setWidth(window.innerWidth); }
    sendDataToGTM({ event: 'giftCardPayment', value: 'failure' });
    
    window.addEventListener("resize", handleWindowResizing);
    return () => {
      window.removeEventListener("resize", handleWindowResizing);
    };
  }, []);

  return (
    <div>
      <WoliHero />
      <Container component="main" maxWidth="lg" sx={{ mb: 4, mt: 15 }}>
        <Box pl={5} pr={5}>
          <Paper
            elevation={0}
            style={{ borderRadius: "25px" }}
            sx={{
              position: "relative",
              backgroundColor: "#e8fafa",
              color: "#fff",
              mb: 4,

              backgroundSize: "contain",
              backgroundRepeat: "repeat-y",
              backgroundPosition: "center 5px",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
              }}
            />
            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    position: "relative",
                    p: { xs: 8, md: 15 },
                  }}
                >
                  <img
                    src={Bell}
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                      marginTop: -50,
                      marginBottom: 40,
                    }}
                    alt="NOTIFICATION BELL"
                  />
                  <Typography
                    fontWeight="bold"
                    align="center"
                    component="h1"
                    variant="h4"
                    color="black"
                    gutterBottom
                  >
                    <Trans>{t("failure1")}</Trans>
                  </Typography>
                  <Typography
                    fontWeight="bold"
                    align="center"
                    component="h1"
                    variant="h4"
                    color="black"
                    gutterBottom
                  >
                    <Trans>{t("failure2")}</Trans>
                  </Typography>
                  <ThemeProvider theme={themeSourceSerifPro}>
                  <Typography
                    align="center"
                    component="h1"
                    variant="body1"
                    color="black"
                    gutterBottom
                    sx={{ marginTop: 10 }}
                  >
                    <Trans>{t("supportFailure")}</Trans>
                    </Typography>
                  </ThemeProvider>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}
