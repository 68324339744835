import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import GiftcardHero from "../assets/images/giftcard_hero.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { useTranslation, Trans } from "react-i18next";
import { themeApercu } from "../themeConstants.js";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "25px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "25px",
  },
};

export default function WoliHero() {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Box pl={5} pr={5} pt={1} pb={0}>
        <Paper
          elevation={0}
          style={{ borderRadius: "25px" }}
          sx={{
            position: "relative",
            backgroundColor: "#67c2bb",
            color: "#fff",
            mb: 4,

            backgroundSize: "contain",
            backgroundRepeat: "repeat-y",
            backgroundPosition: "center 5px",
            backgroundImage: `url(${GiftcardHero})`,
          }}
        >
          {/* Increase the priority of the hero background image */}
          {<img style={{ display: "none" }} src={GiftcardHero} alt="logo" />}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundColor: "rgba(256,256,256,.2)",
            }}
          />
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  position: "relative",
                  p: { xs: 5, md: 19 },
                  //pr: { md: 0 },
                }}
              >
                <ThemeProvider theme={themeApercu}>
                <Typography
                  fontWeight="bold"
                  align="center"
                  component="h1"
                  fontSize={35}
                  variant="h3"
                  color="black"
                  gutterBottom
                  
                >
                  <Trans>{t("woliHero")}</Trans>
                </Typography>
                </ThemeProvider>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
